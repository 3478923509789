import React, { useState, useEffect } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { LeftOutlined } from "@ant-design/icons";
import { useDeepCompareEffect } from "ahooks";
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Result,
  Row,
  Space,
  Typography,
} from "antd";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import DedicatedIntegration from "assets/svgs/dedicated-integration.svg";
import InstantConnection from "assets/svgs/instant-connection.svg";
import PayWithEaseIcon from "assets/svgs/pay-with-ease.svg";
import VolumeDiscounts from "assets/svgs/volume-discounts.svg";
import Loader from "components/ui/Loader";
import { type CreateInvoiceResponse } from "mutations";
import { IntercomForm, INTERCOM_TICKET_TYPE_IDS } from "../intercom-tickets";
import { PLANS } from "./constants";
import PricingInfo from "./PricingInfo";
import "assets/scss/styles.scss";
import { AutoPayConfig, PayCurrency } from "./types";
import WalletTopUpForm from "./WalletTopUpForm";

type PaymentPlanType = "pay_as_you_go" | "contract";
interface PayAsYouGoPlanProps {
  isCurrentPlan?: boolean;
  onSelect?: () => void;
}
const PayAsYouGoPlan: React.FC<PayAsYouGoPlanProps> = ({
  isCurrentPlan,
  onSelect,
}) => {
  const content = (
    <Card
      className={classNames("h-full", { "border-primary-blue": isCurrentPlan })}
      bordered
    >
      <Typography.Title level={3} className="h3">
        Pay-As-You-Go Plan
      </Typography.Title>
      <Typography.Paragraph>
        Recommended for startups looking for a flexible solution.
      </Typography.Paragraph>

      <Space direction="vertical" size="large">
        {[
          {
            title: "Get Instant Access",
            description:
              "Add funds to your wallet and use them to access all Smile ID products.",
            icon: InstantConnection,
          },
          {
            title: "Use Your Preferred Payment Method",
            description:
              "Pay with ease with your international cards and bank accounts.",
            icon: PayWithEaseIcon,
          },
        ].map(({ title, description, icon }) => (
          <div
            className="grid gap-4 items-center grid-cols-[auto_1fr]"
            key={title}
          >
            <div>
              <img className="w-10" src={icon} alt="" />
            </div>
            <div>
              <Typography.Title className="mt-0" level={5}>
                {title}
              </Typography.Title>
              <Typography.Paragraph>{description}</Typography.Paragraph>
            </div>
          </div>
        ))}
      </Space>

      {onSelect && !isCurrentPlan && (
        <Row className="justify-end">
          <Button type="primary" onClick={onSelect} size="large">
            Contact Sales
          </Button>
        </Row>
      )}
    </Card>
  );

  return isCurrentPlan ? (
    <Badge.Ribbon text="Current Plan">{content}</Badge.Ribbon>
  ) : (
    content
  );
};

interface ContractPlanProps {
  isCurrentPlan?: boolean;
  onSelect?: () => void;
}

const ContractPlan: React.FC<ContractPlanProps> = ({
  isCurrentPlan,
  onSelect,
}) => {
  const content = (
    <Card
      className={classNames("h-full", { "border-primary-blue": isCurrentPlan })}
      bordered
    >
      <Typography.Title level={3} className="h3">
        Enterprise Plan
      </Typography.Title>
      <Typography.Paragraph>
        A contracted plan recommended for businesses verifying 5000+ users per
        month
      </Typography.Paragraph>

      <Space direction="vertical" size="large">
        {[
          {
            title: "Volume Discounts",
            description: "Get volume based discounted pricing.",
            icon: VolumeDiscounts,
          },
          {
            title: "Dedicated Integration",
            description:
              "Dedicated account management with round the clock help and integration support.",
            icon: DedicatedIntegration,
          },
        ].map(({ title, description, icon }) => (
          <div
            className="grid gap-4 items-center grid-cols-[auto_1fr]"
            key={title}
          >
            <div>
              <img className="w-10" src={icon} alt="" />
            </div>
            <div>
              <Typography.Title className="mt-0" level={5}>
                {title}
              </Typography.Title>
              <Typography.Paragraph>{description}</Typography.Paragraph>
            </div>
          </div>
        ))}
      </Space>

      {onSelect && !isCurrentPlan && (
        <Row justify="end">
          <Button type="primary" onClick={onSelect} size="large">
            Contact Sales
          </Button>
        </Row>
      )}
    </Card>
  );

  return isCurrentPlan ? (
    <Badge.Ribbon text="Current Plan">{content}</Badge.Ribbon>
  ) : (
    content
  );
};

interface PlanChangeRequestFormProps {
  autoPay: AutoPayConfig;
  currentPlan: PaymentPlanType;
  currency: PayCurrency;
  requestedPlan: PaymentPlanType;
  onSubmit: () => void;
  onCreateInvoice: (
    data: CreateInvoiceResponse & { currency: string; amount: number },
  ) => void;
}

const PlanChangeRequestForm: React.FC<PlanChangeRequestFormProps> = ({
  onSubmit,
  currentPlan,
  currency,
  requestedPlan,
  autoPay,
  onCreateInvoice,
}) => {
  const [{ step }] = useUrlState();

  const shouldContactSales =
    step !== "add-funds" &&
    (currentPlan !== requestedPlan || requestedPlan === "contract");
  const [loading, setLoading] = useState(!shouldContactSales);

  useDeepCompareEffect(() => {
    if (!shouldContactSales && autoPay.enabledCurrencies) {
      setLoading(false);
    }
  }, [autoPay]);

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <div className="grid grid-cols-2 gap-4 pt-9">
      {shouldContactSales ? (
        <div>
          <Typography.Title level={3} className="h3">
            Request {PLANS[requestedPlan]}
          </Typography.Title>
          <Typography.Paragraph>
            Give us some quick details.
          </Typography.Paragraph>
          <IntercomForm
            onSubmit={onSubmit}
            initialValues={{ requested_plan: requestedPlan }}
            hiddenFields={["partner_id", "requested_plan"]}
            ticketTypeId={INTERCOM_TICKET_TYPE_IDS.changePaymentPlan}
          />
        </div>
      ) : (
        <WalletTopUpForm
          autoPay={autoPay}
          designatedCurrency={currency}
          onCreateInvoice={onCreateInvoice}
        />
      )}
      {requestedPlan === "contract" ? <ContractPlan /> : <PayAsYouGoPlan />}
    </div>
  );
};

interface PaymentPlanModalProps {
  isOpen: boolean;
  paymentPlan: PaymentPlanType;
  autoPay: AutoPayConfig;
  designatedCurrency: PayCurrency;
  onCreateInvoice: (
    data: CreateInvoiceResponse & { currency: string; amount: number },
  ) => void;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
  isOpen,
  paymentPlan,
  autoPay,
  designatedCurrency,
  onCreateInvoice,
}) => {
  const history = useHistory();
  const [{ step }] = useUrlState();

  const screens = [
    "planSelection",
    "selected",
    "changeRequestSubmitted",
    "pricingInfo",
  ];
  const [screen, setScreen] = useState(screens[0]);
  const [showBackButton, setShowBackButton] = useState(true);

  const currentPaymentPlan = paymentPlan || "pay_as_you_go";
  const [selectedPlan, setSelectedPlan] =
    useState<PaymentPlanType>(currentPaymentPlan);

  useEffect(() => {
    if (step === "add-funds") {
      setScreen("selected");
      setShowBackButton(false);
    }
  }, [step]);

  const onClose = () => {
    // reset states
    setScreen(screens[0]);
    setShowBackButton(true);
    setSelectedPlan(paymentPlan || "pay_as_you_go");
    history.replace("/partner/billing");
  };

  const goToPreviousScreen = () => {
    if (screen === "pricingInfo") {
      setScreen("selected");
      return;
    }
    if (screen === "selected") {
      setScreen("planSelection");
    }
  };

  const enabledCurrenciesIsOnlyUsd = autoPay.enabledCurrencies.every(
    (currency) => currency === "USD",
  );

  return (
    <Modal
      className="max-w-5xl"
      destroyOnClose
      footer={
        screen === "selected" &&
        designatedCurrency === "USD" &&
        selectedPlan === "pay_as_you_go" &&
        paymentPlan === "pay_as_you_go" &&
        enabledCurrenciesIsOnlyUsd ? (
          <Alert
            className="mt-6"
            type="info"
            message={
              <div className="flex gap-4 justify-between items-center text-start">
                <div>
                  <Typography.Title level={5}>AML Checks</Typography.Title>
                  <Typography.Paragraph>
                    Available in all markets.
                  </Typography.Paragraph>
                </div>
                <div>
                  <Typography.Title level={5}>
                    Government ID Checks
                  </Typography.Title>
                  <Typography.Paragraph>Specific Markets.</Typography.Paragraph>
                </div>
                <div>
                  <Typography.Title level={5}>
                    Document Verification
                  </Typography.Title>
                  <Typography.Paragraph>
                    Available in all markets.
                  </Typography.Paragraph>
                </div>
                <div>
                  <Button
                    ghost
                    type="primary"
                    onClick={() => setScreen("pricingInfo")}
                    size="large"
                  >
                    View Pricing
                  </Button>
                </div>
              </div>
            }
          />
        ) : null
      }
      onCancel={onClose}
      open={isOpen}
      width="80%"
    >
      {showBackButton &&
        screen !== "changeRequestSubmitted" &&
        screen !== "planSelection" && (
          <div>
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={goToPreviousScreen}
            >
              Go Back
            </Button>
          </div>
        )}
      {
        {
          planSelection: (
            <>
              <Typography.Title level={3}>
                Choose The Best Plan For Your Business
              </Typography.Title>
              <Typography.Paragraph>
                Your current plan: <strong>{PLANS[currentPaymentPlan]}</strong>
              </Typography.Paragraph>
              <div className="grid grid-cols-2 gap-6 items-stretch pt-9">
                <PayAsYouGoPlan
                  isCurrentPlan={currentPaymentPlan === "pay_as_you_go"}
                  onSelect={() => {
                    setSelectedPlan("pay_as_you_go");
                    setScreen("selected");
                  }}
                />
                <ContractPlan
                  isCurrentPlan={currentPaymentPlan === "contract"}
                  onSelect={() => {
                    setSelectedPlan("contract");
                    setScreen("selected");
                  }}
                />
              </div>
            </>
          ),
          selected: (
            <PlanChangeRequestForm
              autoPay={autoPay}
              currentPlan={paymentPlan}
              requestedPlan={selectedPlan}
              onSubmit={() => setScreen("changeRequestSubmitted")}
              currency={designatedCurrency}
              onCreateInvoice={onCreateInvoice}
            />
          ),
          changeRequestSubmitted: (
            <Result
              status="success"
              title="Details Submitted"
              subTitle="You should receive an email response in 1-3 business days."
              extra={[
                <Button key="ok" type="primary" onClick={onClose}>
                  Continue
                </Button>,
              ]}
            />
          ),
          pricingInfo: <PricingInfo />,
        }[screen]
      }
    </Modal>
  );
};

export default PaymentPlanModal;
