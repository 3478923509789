import { camelCase, isArray, isObject, transform } from "lodash";

/**
 * Recursively transforms an object's keys to camelCase.
 * This function is intended to be used when interacting with APIs that
 * use other casings for their json keys, but expects camelCase in the
 * app.
 * @param {object} obj - The object to transform
 * @param {string[]} exemptions - Keys to exempt
 * @returns {object} A new object with camelCased keys
 * @example
 * const obj = { foo_bar: 'baz', nested: { hello_world: 'goodbye' } };
 * const camelized = camelize(obj);
 * // camelized is now { fooBar: 'baz', nested: { helloWorld: 'goodbye' } }
 */
export const camelize = <T = unknown>(
  obj: object,
  exemptions: string[] = [],
): T =>
  transform(obj, (acc, value: string, key: string | number, target) => {
    const isEnum =
      typeof key === "string" &&
      !key.includes(" ") &&
      key.toUpperCase() === key;

    const camelCaseKey =
      typeof key !== "string" || isEnum || exemptions.includes(key)
        ? key
        : camelCase(key);
    const newKey = isArray(target) ? key : camelCaseKey;
    acc[newKey] = isObject(value) ? camelize(value, exemptions) : value;
  });
