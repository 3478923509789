import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PayCurrency } from "components/payment-plans/types";

interface GetPricingPlanRawResponse {
  id: number;
  contract_type: "pay_as_you_go" | "contract";
  minimum_wallet_balance_usd: string;
  recurring_top_up_amount_usd: string;
  projected_monthly_spend_usd: string;
  contract_duration_start: string | null;
  contract_duration_end: string | null;
  audit_log: { name: string; email: string };
  created_at: string;
  partner_id: string;
  company_id: number;
  discount_percentage: number;
  designated_currency: PayCurrency | "KSH";
  enabled_currencies: (PayCurrency | "KSH")[];
  auto_pay_enabled: boolean;
  end_at: string | null;
  wallet_balance: string;
  overdraft_allowance: string;
}

interface GetPricingPlanResponse {
  pricingPlan: {
    contractDurationStart: string | null;
    contractDurationEnd: string | null;
    discountPercentage: number;
    contractType: "pay_as_you_go" | "contract";
    minimumWalletBalanceUSD: number;
    projectedMonthlySpendUSD: number;
    recurringTopUpAmountUSD: number;
    designatedCurrency: PayCurrency;
    enabledCurrencies: PayCurrency[];
    autoPayEnabled: boolean;
  };
  companyWalletDetails: {
    overdraftAllowance: string;
    walletBalance: string;
  };
}

const getPricingPlan = async (
  partnerId: string,
): Promise<GetPricingPlanResponse> => {
  const { data } = await axios.get<GetPricingPlanRawResponse>(
    partnerId
      ? `/company_pricing_plans/${partnerId}`
      : "/company_pricing_plans/me",
  );

  return {
    pricingPlan: {
      contractDurationStart: data.contract_duration_start,
      contractDurationEnd: data.contract_duration_end,
      discountPercentage: data.discount_percentage,
      contractType: data.contract_type,
      minimumWalletBalanceUSD: parseFloat(data.minimum_wallet_balance_usd),
      projectedMonthlySpendUSD: parseFloat(data.projected_monthly_spend_usd),
      recurringTopUpAmountUSD: parseFloat(data.recurring_top_up_amount_usd),
      designatedCurrency:
        data.designated_currency === "KSH"
          ? "KES"
          : (data.designated_currency as PayCurrency),
      enabledCurrencies: data.enabled_currencies.map((currency) =>
        currency === "KSH" ? "KES" : (currency as PayCurrency),
      ),
      autoPayEnabled: data.auto_pay_enabled,
    },
    companyWalletDetails: {
      overdraftAllowance: data.overdraft_allowance,
      walletBalance: data.wallet_balance,
    },
  };
};

export const PRICING_PLAN_QUERY_KEY = "pricing-plan";

export const usePricingPlan = (
  partnerId: string,
  options?: Omit<
    UseQueryOptions<GetPricingPlanResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PRICING_PLAN_QUERY_KEY, partnerId],
    queryFn: () => getPricingPlan(partnerId),
    ...options,
  });
