import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { camelize } from "util/transformers";

interface GetPaymentStatusResponse {
  success: true;
  data: {
    status:
      | "PAID"
      | "PENDING"
      | "REJECTED"
      | "CANCELLED"
      | "AUTHORIZED"
      | "VERIFIED";
    code: number;
    /** 100 = PENDING, 200 = PAID, 300 = REJECTED, 400 = CANCELLED, 500 = AUTHORIZED, 600 = VERIFIED */
    statusCode: string;
    statusDetail: string;
    message: string;
  };
}

interface GetPaymentStatusInput {
  country: string;
  payment_ref: string;
  provider: "dlocal";
}

const getPaymentStatus = async (params: GetPaymentStatusInput) => {
  const { data } = await axios.get<GetPaymentStatusResponse>(
    "/dlocal/payment_status",
    { params },
  );

  return camelize<GetPaymentStatusResponse>(data);
};

export const PAYMENT_STATUS_QUERY_KEY = "payment-status";

export const usePaymentStatus = (
  input: GetPaymentStatusInput,
  options?: Omit<
    UseQueryOptions<GetPaymentStatusResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PAYMENT_STATUS_QUERY_KEY, input],
    queryFn: () => getPaymentStatus(input),
    ...options,
  });
