export const getCurrencyName = (currency: string) => {
  const currencyNames = new Intl.DisplayNames("en", { type: "currency" });
  return currencyNames.of(currency);
};

export const getCurrencyCountry = (currency: string) =>
  currency.substring(0, 2);

export const formatMoney = (
  amount: number,
  currency: string,
  locale = "en-GB",
) =>
  new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  }).format(amount);

export const getCountryName = (country: string) => {
  const countryNames = new Intl.DisplayNames("en", { type: "region" });
  return countryNames.of(country);
};
