import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface GetWalletResponse {
  exchangeRates: {
    KES: number;
    ZAR: number;
    GHS: number;
    NGN: number;
    UGX: number;
    USD: number;
  };
  walletBalance: number;
}

const getWallet = async (partnerId: string): Promise<GetWalletResponse> => {
  const { data } = await axios.get("/invoices/wallet", {
    params: { partner_id: partnerId },
  });

  const exchangeRates = data.exchange_rates;
  Object.keys(exchangeRates).forEach((key) => {
    exchangeRates[key] = parseFloat(exchangeRates[key]);
  });

  return {
    exchangeRates,
    walletBalance: parseFloat(data.wallet_balance),
  };
};

export const WALLET_QUERY_KEY = "wallet";

export const useWallet = (
  partnerId: string,
  options?: Omit<
    UseQueryOptions<GetWalletResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [WALLET_QUERY_KEY, partnerId],
    queryFn: () => getWallet(partnerId),
    ...options,
  });
