import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  AUTOPAY_CARDS_QUERY_KEY,
  PRICING_PLAN_QUERY_KEY,
  WALLET_QUERY_KEY,
} from "queries";
import { camelize } from "util/transformers";

interface AutoPayCardSetupResponse {
  dlocalSfKey: string;
  redirectUrl: string;
  hasRedirect: boolean;
}

interface AutoPayCardSetupInput {
  currency: string;
  replace_card?: boolean;
  id?: string;
}

const autoPayCardSetup = async (input: AutoPayCardSetupInput) => {
  const { data } = await axios.post<AutoPayCardSetupResponse>(
    "/payment_methods/card_setup",
    input,
  );
  return camelize<AutoPayCardSetupResponse>(data);
};

export const useAutoPayCardSetup = (
  options?: UseMutationOptions<
    AutoPayCardSetupResponse,
    AxiosError<{ error: string }>,
    AutoPayCardSetupInput
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: autoPayCardSetup,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [AUTOPAY_CARDS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRICING_PLAN_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [WALLET_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
