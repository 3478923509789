import { PayCurrency } from "components/payment-plans/types";

export const convertMoney = (
  amount: number,
  oldCurrency: PayCurrency,
  newCurrency: PayCurrency,
  exchangeRates: Record<PayCurrency, number>,
) => {
  const usdAmount =
    oldCurrency !== "USD" ? amount / exchangeRates[oldCurrency] : amount;
  return newCurrency === "USD"
    ? usdAmount
    : usdAmount * exchangeRates[newCurrency];
};
