import useUrlState from "@ahooksjs/use-url-state";
import { Button, Modal, Result, Typography } from "antd";
import { useHistory } from "react-router-dom";

import { PayCurrency } from "components/payment-plans/types";
import Loader from "components/ui/Loader";
import { GetWalletResponse, useAutoPayCards } from "queries";
import TopUpIcon from "resources/img/icons/card-save.svg";
import AutoPayManageCard from "./AutoPayManageCard";
import AutoPaySetupForm from "./AutoPaySetupForm";

interface AutoPayConfig {
  enabledCurrencies: PayCurrency[];
  designatedCurrency: PayCurrency;
  enabled: boolean;
  minBalance: number;
  payAmount: number;
  exchangeRates: GetWalletResponse["exchangeRates"];
}

interface AutoPayModalProps {
  autoPay: AutoPayConfig;
  isOpen: boolean;
  onPageChange: () => void;
}

const AutoPayModal: React.FC<AutoPayModalProps> = ({
  autoPay,
  isOpen,
  onPageChange,
}) => {
  const history = useHistory();
  const { data: cards, isLoading } = useAutoPayCards(
    { currency: autoPay.designatedCurrency },
    { enabled: !!autoPay.designatedCurrency },
  );
  const card = cards?.paymentMethods[0];

  const [{ step }] = useUrlState();
  const pages = ["setup", "cardAdded", "manageCard", "removeCard"];
  const page = step || pages[0];

  const onClose = () => {
    history.replace("billing");
  };

  if (isLoading && isOpen) {
    return <Loader size="large" />;
  }

  const autoPayInfo = (
    <div className="px-8 py-12 rounded-2xl border border-solid border-black">
      <Typography.Title level={3} className="h2">
        Auto-Pay
      </Typography.Title>
      <Typography.Paragraph className="h4 text-secondary-dark-green">
        Never run out of credits
      </Typography.Paragraph>
      <ul className="flex flex-col gap-4 list-none">
        {[
          {
            title: "Minimum Balance",
            description:
              "Your card on file will get charged automatically when your wallet balance reaches this amount.",
          },
          {
            title: "Auto-Pay Amount",
            description:
              "This is the amount you will get charged when your wallet reaches the minimum balance.",
          },
        ].map(({ title, description }) => (
          <li className="flex justify-center items-center gap-4" key={title}>
            <img className="w-10 h-10" src={TopUpIcon} alt="" />
            <div>
              <Typography.Title className="h4" level={4}>
                {title}
              </Typography.Title>
              <Typography.Paragraph className="text-secondary-dark-green">
                {description}
              </Typography.Paragraph>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <Modal
        open={isOpen && page === "cardAdded"}
        footer={null}
        onCancel={onClose}
        title={null}
      >
        <Result
          status="success"
          title="Auto-Pay Set Up Successfully"
          extra={[
            <Button type="primary" key="ok" onClick={onClose}>
              Done
            </Button>,
          ]}
        />
      </Modal>
      <Modal
        className="max-w-5xl"
        classNames={{ body: "py-8" }}
        footer={null}
        open={isOpen && page === "manageCard"}
        onCancel={onClose}
        title="This is Your Card on File"
        width="80%"
      >
        <div className="grid grid-cols-2 gap-16">
          <AutoPayManageCard
            card={card!}
            autoPay={autoPay}
            onNext={onPageChange}
            onClose={onClose}
          />
          {autoPayInfo}
        </div>
      </Modal>
      <Modal
        className="max-w-5xl"
        classNames={{ body: "py-8" }}
        title="Set Up Auto-Pay"
        footer={null}
        open={isOpen && page === "setup"}
        onCancel={onClose}
        width="80%"
      >
        <div className="grid grid-cols-2 gap-16">
          <AutoPaySetupForm autoPay={autoPay} onSubmit={onPageChange} />
          <div>
            {autoPayInfo}
            {card && (
              <Button
                className="mt-4"
                type="link"
                onClick={() => history.push("?mode=auto-pay&step=manageCard")}
              >
                Manage card on file
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AutoPayModal;
