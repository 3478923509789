import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PayCurrency } from "components/payment-plans/types";

import { PRICING_PLAN_QUERY_KEY } from "queries";

interface DeactivateAutoPayResponse {
  company_id: number;
  end_at: string;
  discount_percentage: number;
  minimum_wallet_balance_usd: string;
  recurring_top_up_amount_usd: string;
  projected_monthly_spend_usd: string;
  id: number;
  contract_type: "pay_as_you_go" | "contract";
  contract_duration_start: string | null;
  contract_duration_end: string | null;
  audit_log: {
    name: string;
    email: string;
  };
  created_at: string;
  partner_id: string;
  designated_currency: PayCurrency;
  enabled_currencies: PayCurrency[];
  auto_pay_enabled: boolean;
}

interface DeactivateAutoPayInput {
  partner_id: string;
  auto_pay_enabled: boolean;
}

const deactivateAutoPay = async (input: DeactivateAutoPayInput) => {
  const { data } = await axios.post<DeactivateAutoPayResponse>(
    "/company_pricing_plans/deactivate_auto_pay",
    input,
  );
  return data;
};

export const useDeactivateAutoPay = (
  options?: UseMutationOptions<
    DeactivateAutoPayResponse,
    AxiosError<{ error: string }>,
    DeactivateAutoPayInput
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deactivateAutoPay,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [PRICING_PLAN_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
