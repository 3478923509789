import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { camelize } from "util/transformers";

export interface CreateInvoiceResponse {
  checkoutUrl?: string;
  dlocalSfKey?: string;
  paymentMethods?: {
    cardId: string;
    last4: string;
    cardType: "visa" | "mastercard" | "verve";
    expiration: string;
  }[];
  invoice: { invoiceId: string };
}

interface CreateInvoiceInput {
  currency: string;
  partner_id: string;
  amount: number;
}

const createInvoice = async (
  input: CreateInvoiceInput,
): Promise<CreateInvoiceResponse> => {
  const { data } = await axios.post<CreateInvoiceResponse>("/invoices", input);
  return camelize<CreateInvoiceResponse>(data);
};

export const useCreateInvoice = (
  options?: UseMutationOptions<
    CreateInvoiceResponse,
    AxiosError<{ error: string }>,
    CreateInvoiceInput
  >,
) =>
  useMutation({
    mutationFn: createInvoice,
    ...options,
  });
