import React, { useEffect, useState } from "react";
import {
  useDeepCompareEffect,
  useExternal,
  useLocalStorageState,
} from "ahooks";
import {
  App,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
  Space,
  Typography,
} from "antd";

import Loader from "components/ui/Loader";
import { useFundWallet } from "mutations";
import { usePaymentStatus } from "queries";
import MastercardIcon from "resources/img/mastercard_logo.svg";
import VerveIcon from "resources/img/verve-logo.png";
import VisaIcon from "resources/img/visa_logo.svg";
import { isProduction } from "util/environment";
import { getCurrencyCountry, getCurrencySymbol } from "util/format_helpers";
import { getCountryName } from "util/i18n";

const dlocalInputStyle = {
  base: {},
  autofilled: {},
};

const supportedCountries = ["KE", "NG", "ZA"];

const cardTypeToIcon = {
  visa: VisaIcon,
  mastercard: MastercardIcon,
  verve: VerveIcon,
};

interface FormValues {
  name: string;
  documentId: string;
  cardId: string;
  phone: string;
  amount: number;
  country: string;
}

interface DLocalPaymentFormProps {
  apiKey: string;
  amount: number;
  currency: string;
  invoiceId: string;
  paymentMethods: {
    cardId: string;
    last4: string;
    cardType: "visa" | "mastercard" | "verve";
  }[];
  onSubmit?: () => void;
}

const DLocalPaymentForm: React.FC<DLocalPaymentFormProps> = ({
  apiKey,
  amount,
  currency,
  invoiceId,
  paymentMethods,
  onSubmit,
}) => {
  const [dLocalInstance, setDLocalInstance] = useState<DLocalInstance>();
  const { notification, message } = App.useApp();
  const [partnerId] = useLocalStorageState<string>("adminPartnerId");
  const [form] = Form.useForm<FormValues>();
  const fullName = Form.useWatch("name", form);
  const mode = Form.useWatch("mode", form);
  const [paymentRef, setPaymentRef] = useState<string>();
  const country = getCurrencyCountry(currency);
  const [isPolling, setIsPolling] = useState(false);
  const { data: paymentStatusData } = usePaymentStatus(
    {
      country,
      payment_ref: paymentRef!,
      provider: "dlocal",
    },
    {
      enabled: Boolean(paymentRef),
      refetchInterval: () => (isPolling ? 3000 : false),
    },
  );

  const [cvv, setCVV] = useState<DLocalFieldInstance>();
  const [cardError, setCardError] =
    useState<Partial<{ [key in "cvv" | "number" | "expiration"]: string }>>();
  const fundWallet = useFundWallet();

  const scriptStatus = useExternal(
    isProduction ? "https://js.dlocal.com/" : "https://js-sandbox.dlocal.com/",
    { js: { defer: true }, type: "js", keepWhenUnused: true },
  );

  useEffect(() => {
    setIsPolling(paymentStatusData?.data.status === "PENDING");

    if (paymentStatusData?.data.status !== "PENDING") {
      message.destroy();
    }

    if (paymentStatusData?.data.status === "PAID") {
      notification.success({ message: "Card saved successfully" });
      onSubmit?.();
    }

    if (paymentStatusData?.data.status === "CANCELLED") {
      notification.info({
        message: "Card setup was cancelled",
      });
    }

    if (paymentStatusData?.data.status === "REJECTED") {
      notification.error({
        message: paymentStatusData.data.message,
        description:
          "Please try again, or contact your account manager or support@usesmileid.com for assistance.",
      });
    }
  }, [paymentStatusData?.data.status]);

  const createToken = async () => {
    try {
      const { token } = await dLocalInstance!.createToken(cvv!, {
        name: fullName,
      });
      return token as string;
    } catch (e) {
      type TokenError = {
        error: { code: number; param: string; message: string };
        message?: string;
      };

      if ((e as TokenError)?.error?.param) {
        setCardError({
          [(e as TokenError).error.param]: (e as TokenError).error.message,
        });
      }

      notification.error({
        message: (e as TokenError).error.message || (e as TokenError).message,
      });
      return null;
    }
  };

  const handleSubmit = async ({
    name,
    documentId,
    cardId,
    phone,
    amount,
    country,
  }: FormValues) => {
    const token = mode === "NEW_CARD" ? await createToken() : undefined;
    if (mode === "NEW_CARD" && !token) {
      return;
    }

    fundWallet.mutate(
      {
        currency,
        country,
        name,
        paymentMethod: mode === "BANK_TRANSFER" ? "BANK_TRANSFER" : "CARD",
        documentId,
        partnerId,
        dlocalToken: token!,
        cardId,
        amount,
        invoiceId,
        phone,
      },
      {
        onSuccess: (resp) => {
          if (resp.redirectUrl) {
            window.location.assign(resp.redirectUrl);
            return;
          }

          if (resp.code === 100) {
            message.open({
              type: "loading",
              content: "Processing payment method...",
              duration: 0,
            });
            setPaymentRef(resp.paymentRef);
            return;
          }
          notification.success({ message: "Card saved successfully" });
          onSubmit?.();
        },
        onError: (e) => {
          notification.error({
            message: e.response?.data.error || "Unable to process card",
            description:
              "Please try again, or contact your account manager or support@usesmileid.com for assistance.",
            duration: 10,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (scriptStatus === "ready" && window.dlocal && !dLocalInstance) {
      setDLocalInstance(window.dlocal(apiKey));
    }
  }, [scriptStatus]);

  useDeepCompareEffect(() => {
    if (!dLocalInstance || mode !== "NEW_CARD") return;

    const fields = dLocalInstance.fields({
      locale: "en",
      country,
    });

    const cardNumberField = fields.create("pan", {
      style: dlocalInputStyle,
    });

    const expirationField = fields.create("expiration", {
      style: dlocalInputStyle,
    });

    const cvvField = fields.create("cvv", {
      style: dlocalInputStyle,
    });
    setCVV(cvvField);

    cardNumberField.mount(document.getElementById("cardNumber")!);
    expirationField.mount(document.getElementById("expiration")!);
    cvvField.mount(document.getElementById("cvv")!);
  }, [dLocalInstance, mode]);

  if (scriptStatus === "loading") {
    return <Loader size="large" />;
  }

  return (
    <Form
      className="pt-4"
      form={form}
      initialValues={{
        country,
        amount,
        mode: paymentMethods.length > 0 ? "EXIST_CARD" : "NEW_CARD",
      }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item name="mode" label="Pay with">
        <Segmented
          options={[
            ...(paymentMethods.length > 0
              ? [{ label: "Saved card", value: "EXIST_CARD" as const }]
              : []),
            { label: "New card", value: "NEW_CARD" as const },
            { label: "Bank transfer", value: "BANK_TRANSFER" as const },
          ]}
        />
      </Form.Item>
      <Form.Item name="amount" label="Payment amount">
        <InputNumber
          className="w-full"
          disabled
          prefix={getCurrencySymbol(currency)}
        />
      </Form.Item>

      {country === "ZA" && (
        <Form.Item
          name="documentId"
          label="National ID"
          rules={[
            { required: true, message: "National ID is required" },
            {
              pattern: /^[0-9]{13}$/,
              message: "Invalid national ID",
            },
          ]}
        >
          <Input className="w-full" />
        </Form.Item>
      )}

      {mode === "EXIST_CARD" && (
        <Form.Item
          name="cardId"
          label="Payment method"
          rules={[{ required: true, message: "Payment method is required" }]}
        >
          <Select
            className="w-full"
            options={paymentMethods.map((pm) => ({
              label: (
                <Space>
                  <img src={cardTypeToIcon[pm.cardType]} alt="" />
                  <span>****{pm.last4}</span>
                </Space>
              ),
              value: pm.cardId,
            }))}
          />
        </Form.Item>
      )}

      {mode === "NEW_CARD" && (
        <>
          <Form.Item
            name="name"
            label="Full name"
            rules={[
              {
                required: true,
                message: "Full name is required",
                whitespace: true,
              },
            ]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Card number"
            required
            validateStatus={cardError?.number ? "error" : undefined}
            help={cardError?.number}
          >
            <div
              id="cardNumber"
              className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
            />
          </Form.Item>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Expiration"
                required
                validateStatus={cardError?.expiration ? "error" : undefined}
                help={cardError?.expiration}
              >
                <div
                  id="expiration"
                  className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="CVV"
                validateStatus={cardError?.cvv ? "error" : undefined}
                help={cardError?.cvv}
                required
              >
                <div
                  id="cvv"
                  className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="country" label="Country">
            <Select
              className="w-full"
              disabled
              options={supportedCountries.map((country) => ({
                label: getCountryName(country),
                value: country,
              }))}
            />
          </Form.Item>
        </>
      )}

      {mode === "BANK_TRANSFER" && country === "KE" && (
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: "Phone is required" },
            {
              pattern: /^\+?[0-9]{11,13}$/,
              message: "Phone number is invalid. Use the format: +254xxxxxxxxx",
            },
          ]}
        >
          <Input className="w-full" type="tel" placeholder="+254xxxxxxxxx" />
        </Form.Item>
      )}

      <Typography.Paragraph>
        By confirming your payment, you allow us to charge your card for future
        payments.
      </Typography.Paragraph>
      <Row justify="end">
        <Form.Item>
          <Button
            loading={fundWallet.isPending}
            htmlType="submit"
            type="primary"
          >
            Make Payment
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default DLocalPaymentForm;
