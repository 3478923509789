import { RightOutlined } from "@ant-design/icons";
import { App, Button, Form, InputNumber, Radio, Row } from "antd";
import { useHistory } from "react-router-dom";

import { MIN_BALANCE } from "components/auto_pay/constants";
import { PayCurrency } from "components/payment-plans/types";
import { useAutoPayCardSetup, useUpdateAutoPay } from "mutations";
import { GetWalletResponse, useAutoPayCards } from "queries";
import { convertMoney } from "util/converters";
import { formatCurrency, getCurrencySymbol } from "util/format_helpers";
import { getCurrencyName } from "util/i18n";

interface AutoPayConfig {
  enabledCurrencies: PayCurrency[];
  designatedCurrency: PayCurrency;
  enabled: boolean;
  minBalance: number;
  payAmount: number;
  exchangeRates: GetWalletResponse["exchangeRates"];
}

interface FormValues {
  currency: PayCurrency;
  minBalance: number;
  payAmount: number;
}

interface AutoPaySetupFormProps {
  autoPay: AutoPayConfig;
  onSubmit: (data: { dlocalKey: string; currency: string }) => void;
}

const AutoPaySetupForm: React.FC<AutoPaySetupFormProps> = ({
  autoPay,
  onSubmit,
}) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm<FormValues>();
  const currency = Form.useWatch("currency", form);
  const minBalance = Form.useWatch("minBalance", form);
  const history = useHistory();
  const { data: autoPayCards, isLoading: isLoadingCards } = useAutoPayCards({
    currency,
  });
  const updateAutoPay = useUpdateAutoPay();
  const autoPayCardSetup = useAutoPayCardSetup();

  const convertMinBalance = () => {
    let convertedAmount = autoPay.exchangeRates[currency] * MIN_BALANCE;
    if (currency !== "USD") {
      // Round to nearest 1000 for non-USD currencies
      // This make it easy to communicate whole numbers to users
      convertedAmount = Math.ceil(convertedAmount);
      convertedAmount /= 1000;
      convertedAmount = Math.round(convertedAmount);
      convertedAmount *= 1000;
    }

    return convertedAmount;
  };
  const minAmount = convertMinBalance();
  const currencySymbol = getCurrencySymbol(currency);

  const redirectToPaymentProvider = async (currency: string) => {
    autoPayCardSetup.mutate(
      {
        currency,
      },
      {
        onSuccess: (res) => {
          if (res.hasRedirect && res.redirectUrl) {
            window.location.assign(res.redirectUrl);
          } else {
            onSubmit({ dlocalKey: res.dlocalSfKey, currency });
          }
        },
        onError: (res) => {
          notification.error({
            message:
              res.response?.data.error ||
              "Auto-Pay setup failed, please try again",
          });
        },
      },
    );
  };

  const handleSubmit = async ({
    currency,
    minBalance,
    payAmount,
  }: FormValues) => {
    if (isLoadingCards) {
      return;
    }

    updateAutoPay.mutate(
      {
        minimum_wallet_balance_usd: minBalance,
        recurring_top_up_amount_usd: payAmount,
        designated_currency: currency,
        auto_pay_enabled: autoPayCards!.paymentMethods.length > 0,
      },
      {
        onSuccess: () => {
          if (autoPayCards!.paymentMethods.length > 0) {
            notification.success({ message: "Auto-pay updated successfully" });
            history.goBack();
          } else {
            redirectToPaymentProvider(currency);
          }
        },
        onError: (res) => {
          if (res.response?.data.error) {
            notification.error({ message: res.response.data.error });
          }
        },
      },
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        currency: autoPay.designatedCurrency,
        minBalance: autoPay.minBalance,
        payAmount: autoPay.payAmount,
      }}
    >
      <Form.Item name="currency" label="Currency">
        <Radio.Group
          className="flex flex-wrap justify-start gap-4"
          onChange={(e) => {
            form.setFieldsValue({
              minBalance: convertMoney(
                form.getFieldValue("minBalance") || 0,
                currency,
                e.target.value as PayCurrency,
                autoPay.exchangeRates,
              ),
              payAmount: convertMoney(
                form.getFieldValue("payAmount") || 0,
                currency,
                e.target.value as PayCurrency,
                autoPay.exchangeRates,
              ),
            });
          }}
          size="large"
        >
          {autoPay.enabledCurrencies.map((currency) => (
            <Radio.Button
              className="rounded-full border-s before:hidden"
              key={currency}
              value={currency}
            >
              {currency} - {getCurrencyName(currency)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="minBalance"
        label="Minimum balance"
        dependencies={["currency"]}
        rules={[
          { required: true },
          {
            type: "number",
            min: minAmount,
            message: `Amount should be ${formatCurrency(minAmount, currency)} or more`,
          },
        ]}
      >
        <InputNumber
          className="w-full"
          prefix={currencySymbol}
          precision={2}
          size="large"
        />
      </Form.Item>

      <Form.Item
        name="payAmount"
        label="Auto-pay amount"
        dependencies={["currency", "minBalance"]}
        rules={[
          { required: true },
          {
            type: "number",
            min: Math.max(minAmount, minBalance + 0.01),
            message: `Amount should be ${formatCurrency(Math.max(minAmount, minBalance + 0.01), currency)} or more`,
          },
        ]}
      >
        <InputNumber
          className="w-full"
          prefix={currencySymbol}
          precision={2}
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <Button
            disabled={isLoadingCards}
            htmlType="submit"
            icon={<RightOutlined aria-hidden />}
            iconPosition="end"
            loading={updateAutoPay.isPending}
            type="primary"
            size="large"
          >
            Continue
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default AutoPaySetupForm;
