import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { camelize } from "util/transformers";

export interface CreateIntercomTicketResponse {
  message: string;
}

interface CreateIntercomTicketInput {
  ticket_type_id: string;
  ticket_attributes: Record<string, string | number>;
}

const createIntercomTicket = async (
  input: CreateIntercomTicketInput,
): Promise<CreateIntercomTicketResponse> => {
  const { data } = await axios.post<CreateIntercomTicketResponse>(
    "/intercom_tickets/create",
    input,
  );
  return camelize<CreateIntercomTicketResponse>(data);
};

export const useCreateIntercomTicket = (
  options?: UseMutationOptions<
    CreateIntercomTicketResponse,
    AxiosError<{ error: { message: string } }>,
    CreateIntercomTicketInput
  >,
) =>
  useMutation({
    mutationFn: createIntercomTicket,
    ...options,
  });
