import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import {
  AUTOPAY_CARDS_QUERY_KEY,
  PRICING_PLAN_QUERY_KEY,
  WALLET_QUERY_KEY,
} from "queries";

interface DeletePaymentMethodResponse {}

interface DeletePaymentMethodInput {
  id: string;
}

const deletePaymentMethod = async ({ id }: DeletePaymentMethodInput) => {
  const { data } = await axios.delete<DeletePaymentMethodResponse>(
    `/payment_methods/${id}`,
  );
  return data;
};

export const useDeletePaymentMethod = (
  options?: UseMutationOptions<
    DeletePaymentMethodResponse,
    AxiosError<{ error: string }>,
    DeletePaymentMethodInput
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePaymentMethod,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [AUTOPAY_CARDS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [WALLET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRICING_PLAN_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
