import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { camelize } from "util/transformers";

export interface FundWalletResponse {
  success: boolean;
  code: number;
  message: string;
  paymentRef?: string;
  country: string;
  redirectUrl?: string;
}

export interface FundWalletInput {
  amount: number;
  currency: string;
  invoiceId: string;
  country: string;
  documentId: string;
  partnerId?: string;
  phone: string;
  name?: string;
  paymentMethod: "CARD" | "BANK_TRANSFER";
  dlocalToken?: string;
  cardId: string;
}

const fundWallet = async (
  input: FundWalletInput,
): Promise<FundWalletResponse> => {
  const { data } = await axios.post<FundWalletResponse>(
    "/dlocal/fund_wallet",
    input,
  );
  return camelize<FundWalletResponse>(data);
};

export const useFundWallet = (
  options?: UseMutationOptions<
    FundWalletResponse,
    AxiosError<{ error: string }>,
    FundWalletInput
  >,
) =>
  useMutation({
    mutationFn: fundWallet,
    ...options,
  });
