import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { EDV_IDS } from "components/payment-plans/constants";
import { camelize } from "util/transformers";

type MarketCountry =
  | "Nigeria"
  | "Ghana"
  | "Kenya"
  | "Uganda"
  | "Tanzania"
  | "Zambia"
  | "South Africa";

type MarketRegion = "Africa" | "Europe";

const marketCountries = [
  "Nigeria",
  "Ghana",
  "Kenya",
  "Uganda",
  "Tanzania",
  "Zambia",
  "South Africa",
];
const marketRegions = ["Africa", "Europe"];

interface GetPartnerInfoInitialResponse {
  partner: {
    id: number;
    email: string;
    createdAt: string;
    updatedAt: string;
    smilePartnerId: string;
    companyId: number;
    name: string;
    phone: string | null;
    enrolleeId: string | null;
    status: string;
    groupId: number;
    consumedTimestep: string | null;
    otpRequiredForLogin: string | null;
    products: {
      computerName: string;
      components: {
        [key: string]: {
          included: boolean;
          price: string;
        };
      };
      credits?: {
        test: {
          remaining: string;
          total: string;
        };
        production: {
          remaining: string;
          total: string;
        };
        development: {
          remaining: string;
          total: string;
        };
      };
      slaTier?: {
        number: string;
        price: string;
      };
      manualBilling?: boolean;
      name: string;
      idValidation?: {
        [key in MarketCountry]: {
          [key: string]: {
            dataReturn: {
              consentRequired?: boolean;
              included: boolean;
              price: string;
            };
            included: boolean;
            faceCompare?: {
              included: boolean;
              price: string;
            };
            verification?: {
              included: boolean;
              price: string;
            };
          };
        };
      };
      sku: string;
      regions?: {
        [key in MarketRegion]: {
          included: boolean;
          price: string;
        };
      };
      businessVerification?: {
        [key in MarketCountry]: {
          [key in
            | "BUSINESS_REGISTRATION"
            | "BASIC_BUSINESS_REGISTRATION"
            | "TAX_INFORMATION"]: {
            dataReturn: {
              included: boolean;
              price: string;
            };
            included: boolean;
          };
        };
      };
      phoneNumberVerification?: {
        [key in MarketCountry]: {
          phoneNumber: {
            included: boolean;
            verification: {
              included: boolean;
              price: string;
            };
          };
        };
      };
      addressVerification?: {
        [key in MarketCountry]: {
          address: {
            included: boolean;
            verification: {
              included: boolean;
              price: string;
            };
          };
        };
      };
    }[];
    productionEnabled: boolean;
    showNimcEnterpriseId: string | null;
    company: {
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      partnerId: string;
      acceptedTermsAndConditions: string | null;
      walletBalance: string;
      kycState: string;
      zendeskOrganizationId: string | null;
      stripeCustomerId: string;
      ninState: string;
      nimcEnterpriseId: string | null;
      internal: boolean;
      overdraftAllowance: string;
      deletedAt: string | null;
    };
  };
}

interface GetPartnerInfoResponse extends GetPartnerInfoInitialResponse {
  productPricing: {
    aml: number;
    docV: number;
    businessVerification?: {
      country: string;
      services: {
        service: string;
        cost: number;
      }[];
    }[];
    basicKyc?: {
      country: string;
      services: {
        service: string;
        cost: number;
      }[];
    }[];
    enhancedKyc?: {
      country: string;
      services: {
        service: string;
        cost: number;
      }[];
    }[];
    biometricKyc?: {
      country: string;
      services: {
        service: string;
        cost: number;
      }[];
    }[];
    enhancedDocVKyc?: {
      country: string;
      services: {
        service: string;
        cost: number;
      }[];
    }[];
    others?: { service: string; cost: number }[];
  };
}

interface GetPartnerInfoInput {
  adminPartnerId?: string;
}

const getProductPricing = (
  products: GetPartnerInfoInitialResponse["partner"]["products"],
): GetPartnerInfoResponse["productPricing"] => {
  const amlProduct = products.find((p) => p.computerName === "aml_check");
  const aml = Math.min(
    ...Object.values(amlProduct!.components).map((component) =>
      parseFloat(component.price),
    ),
  );

  const docVProduct = products.find(
    (p) => p.computerName === "doc_verification",
  );
  const docV =
    Math.min(
      ...Object.values(docVProduct!.components).map((component) =>
        parseFloat(component.price),
      ),
    ) + parseFloat(docVProduct!.regions!.Africa.price);

  const bizVProduct = products.find(
    (p) => p.computerName === "business_verification",
  );
  const businessVerification = bizVProduct?.businessVerification
    ? Object.keys(bizVProduct.businessVerification).map((countryName) => {
        const services =
          bizVProduct.businessVerification![
            countryName as "Nigeria" | "Kenya" | "South Africa"
          ];
        return {
          country: countryName,
          services: Object.keys(services).map((serviceName) => ({
            service:
              {
                BUSINESS_REGISTRATION: "Business registration",
                BASIC_BUSINESS_REGISTRATION: "Basic business registration",
                TAX_INFORMATION: "Tax information",
              }[serviceName] || serviceName,
            cost: parseFloat(
              services[serviceName as "BUSINESS_REGISTRATION"].dataReturn.price,
            ),
          })),
        };
      })
    : undefined;

  const otherProduct = products.find(
    (p) => p.computerName === "authentication",
  );
  const others = otherProduct
    ? [
        {
          service: "User Authentication",
          cost: parseFloat(otherProduct.components["2"].price),
        },
        {
          service: "Register a Selfie",
          cost: parseFloat(otherProduct.components["4"].price),
        },
      ]
    : undefined;

  const kycServiceNames = {
    NEW_VOTER_ID: "New Voter ID",
    VOTER_ID: "Voter ID",
    NATIONAL_ID: "National ID",
    NATIONAL_ID_NO_PHOTO: "National ID (no photo)",
    PASSPORT: "Passport",
    BANK_ACCOUNT: "Bank Account",
    NIN_V2: "NIN V2",
    NIN_SLIP: "NIN Slip",
    V_NIN: "V NIN",
    BVN: "BVN",
    BVN_MFA: "BVN MFA",
    TIN: "TIN",
    DRIVERS_LICENSE: "Drivers License",
    SSNIT: "SSNIT",
    PHONE_NUMBER: "Phone Number",
    ALIEN_CARD: "Alien Card",
    KRA_PIN: "KRA PIN",
    NIDA: "NIDA",
    TPIN: "TPIN",
  };

  const kycProduct = products.find((p) => p.computerName === "id_validation");
  const basicKyc = kycProduct?.idValidation
    ? Object.keys(kycProduct.idValidation)
        .filter(
          (countryName) =>
            kycProduct.idValidation?.[countryName as "Nigeria"].included,
        )
        .map((countryName) => {
          const services = kycProduct.idValidation![countryName as "Nigeria"];
          return {
            country: countryName,
            services: Object.keys(services)
              .filter(
                (serviceName) =>
                  services[serviceName].included &&
                  services[serviceName].verification?.included,
              )
              .map((serviceName) => ({
                service:
                  kycServiceNames[
                    serviceName as keyof typeof kycServiceNames
                  ] || serviceName,
                cost:
                  parseFloat(kycProduct.components["5"].price) +
                  parseFloat(services[serviceName].dataReturn.price),
              })),
          };
        })
        .filter(({ services }) => services.length > 0)
    : undefined;

  const enhancedKyc = kycProduct?.idValidation
    ? Object.keys(kycProduct.idValidation)
        .filter(
          (countryName) =>
            kycProduct.idValidation?.[countryName as "Nigeria"].included,
        )
        .map((countryName) => {
          const services = kycProduct.idValidation![countryName as "Nigeria"];
          return {
            country: countryName,
            services: Object.keys(services)
              .filter(
                (serviceName) =>
                  services[serviceName].included &&
                  services[serviceName].dataReturn?.included,
              )
              .map((serviceName) => ({
                service:
                  kycServiceNames[
                    serviceName as keyof typeof kycServiceNames
                  ] || serviceName,
                cost:
                  parseFloat(kycProduct.components["5"].price) +
                  parseFloat(services[serviceName].dataReturn.price),
              })),
          };
        })
        .filter(({ services }) => services.length > 0)
    : undefined;

  const biometricKyc = kycProduct?.idValidation
    ? Object.keys(kycProduct.idValidation)
        .filter(
          (countryName) =>
            kycProduct.idValidation?.[countryName as "Nigeria"].included,
        )
        .map((countryName) => {
          const services = kycProduct.idValidation![countryName as "Nigeria"];
          return {
            country: countryName,
            services: Object.keys(services)
              .filter(
                (serviceName) =>
                  services[serviceName].included &&
                  services[serviceName].dataReturn?.included &&
                  services[serviceName].faceCompare?.included,
              )
              .map((serviceName) => ({
                service:
                  kycServiceNames[
                    serviceName as keyof typeof kycServiceNames
                  ] || serviceName,
                cost:
                  parseFloat(services[serviceName].faceCompare!.price) +
                  parseFloat(services[serviceName].dataReturn.price),
              })),
          };
        })
        .filter(({ services }) => services.length > 0)
    : undefined;

  const enhancedDocVKyc = kycProduct?.idValidation
    ? Object.keys(kycProduct.idValidation)
        .filter(
          (countryName) =>
            kycProduct.idValidation?.[countryName as "Nigeria"].included,
        )
        .map((countryName) => {
          const services = kycProduct.idValidation![countryName as "Nigeria"];
          return {
            country: countryName,
            services: Object.keys(services)
              .filter(
                (serviceName) =>
                  services[serviceName].included &&
                  services[serviceName].dataReturn?.included &&
                  EDV_IDS[countryName as "Nigeria"]?.includes(serviceName),
              )
              .map((serviceName) => ({
                service:
                  kycServiceNames[
                    serviceName as keyof typeof kycServiceNames
                  ] || serviceName,
                cost: docV + parseFloat(services[serviceName].dataReturn.price),
              })),
          };
        })
        .filter(({ services }) => services.length > 0)
    : undefined;

  return {
    aml,
    docV,
    businessVerification,
    basicKyc,
    enhancedKyc,
    biometricKyc,
    enhancedDocVKyc,
    others,
  };
};

const getPartnerInfo = async ({
  adminPartnerId,
}: GetPartnerInfoInput): Promise<GetPartnerInfoResponse> => {
  const { data } = await axios.get<GetPartnerInfoInitialResponse>(
    "/companies/partner_info",
    { params: { partner: adminPartnerId } },
  );

  const camelizedData = camelize<GetPartnerInfoInitialResponse>(data, [
    ...marketCountries,
    ...marketRegions,
  ]);
  return {
    ...camelizedData,
    productPricing: getProductPricing(camelizedData.partner.products),
  };
};

export const PARTNER_INFO_QUERY_KEY = "partner-info";

export const usePartnerInfo = (
  input: GetPartnerInfoInput,
  options?: Omit<
    UseQueryOptions<GetPartnerInfoResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PARTNER_INFO_QUERY_KEY, input],
    queryFn: () => getPartnerInfo(input),
    ...options,
  });
