import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import {
  AUTOPAY_CARDS_QUERY_KEY,
  PRICING_PLAN_QUERY_KEY,
  WALLET_QUERY_KEY,
} from "queries";

interface UpdateAutoPayResponse {}

interface UpdateAutoPayInput {
  minimum_wallet_balance_usd: number;
  recurring_top_up_amount_usd: number;
  designated_currency: string;
  auto_pay_enabled: boolean;
}

const updateAutoPay = async (input: UpdateAutoPayInput) => {
  const { data } = await axios.post<UpdateAutoPayResponse>(
    "/company_pricing_plans/change_auto_pay_setup",
    input,
  );
  return data;
};

export const useUpdateAutoPay = (
  options?: UseMutationOptions<
    UpdateAutoPayResponse,
    AxiosError<{ error: string }>,
    UpdateAutoPayInput
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAutoPay,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [AUTOPAY_CARDS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRICING_PLAN_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [WALLET_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
