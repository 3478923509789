import React from "react";
import Select, { SingleValue } from "react-select";

import PlaceAutocomplete from "components/partner/web_app/AutoComplete";
import InfoIcon from "resources/img/icons/info--grey.svg";

export const utilityProviders = [
  {
    label: "ABA",
    value: "ABA",
  },
  {
    label: "AEDC",
    value: "AEDC",
  },
  {
    label: "BEDC",
    value: "BEDC",
  },
  {
    label: "EKEDC",
    value: "EKEDC",
  },
  {
    label: "IBEDC",
    value: "IBEDC",
  },
  {
    label: "IKEDC",
    value: "IKEDC",
  },
  {
    label: "JEDC",
    value: "JEDC",
  },
  {
    label: "KAEDCO",
    value: "KAEDCO",
  },
  {
    label: "KEDCO",
    value: "KEDCO",
  },
  {
    label: "PHEDC",
    value: "PHEDC",
  },
  {
    label: "PEDC",
    value: "PEDC",
  },
  {
    label: "YEDC",
    value: "YEDC",
  },
];

type Props = {
  country: string;
  setAddress: (address: string | undefined) => void;
  setCountry: (value: SingleValue<{ value: string }>) => void;
  setFormField: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUtilityProvider: (value: SingleValue<{ value: string }>) => void;
};

function AddressVerificationFields({
  country,
  setFormField,
  setCountry,
  setUtilityProvider,
  setAddress,
}: Props) {
  const [selectedPlace, setSelectedPlace] =
    React.useState<google.maps.places.PlaceResult | null>(null);

  React.useEffect(() => {
    setAddress(selectedPlace?.formatted_address);
  }, [selectedPlace]);

  const idLabel = country === "NG" ? "Utility Number" : "ID Number";
  return (
    <>
      <div className="web-app__wizard-form-item">
        <label className="label">
          Country
          <span className="asterick">*</span>
          <span className="web-app__tooltip">
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <Select
          options={[
            { label: "South Africa", value: "ZA" },
            { label: "Nigeria", value: "NG" },
          ]}
          name="country"
          placeholder="Select the country"
          className="input"
          onChange={setCountry}
        />
      </div>
      <div className="web-app__wizard-form-item">
        <label className="label">
          Address<span className="asterick">*</span>
        </label>
        <PlaceAutocomplete
          setAddress={setAddress}
          className="input"
          onPlaceSelect={setSelectedPlace}
        />
      </div>
      <div className="web-app__wizard-form-item">
        <label className="label">
          {idLabel}
          <span className="asterick">*</span>
        </label>
        <input
          className="input"
          name="id_number"
          type="text"
          onChange={setFormField}
        />
      </div>
      {country === "NG" && (
        <div className="web-app__wizard-form-item">
          <label className="label">
            Utility Provider
            <span className="asterick">*</span>
            <span className="web-app__tooltip">
              <img src={InfoIcon} alt="info" />
            </span>
          </label>
          <Select
            options={utilityProviders}
            name="country"
            placeholder="Select the utility provider"
            className="input"
            onChange={setUtilityProvider}
          />
        </div>
      )}
      <div className="web-app__wizard-form-item">
        <label className="label">Full Name</label>
        <input
          className="input"
          name="full_name"
          placeholder="full name"
          type="text"
          onChange={setFormField}
        />
      </div>
    </>
  );
}

export default AddressVerificationFields;
