import React from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { App, Form, Skeleton } from "antd";
import { useCreateIntercomTicket } from "mutations";
import { useIntercomTicketAttributes } from "queries";

type FormValues = Record<string, string | number>;

interface Props {
  initialValues?: Record<string, string | number>;
  hiddenFields?: string[];
  ticketTypeId: string;
  onSubmit?: () => void;
}

const IntercomForm: React.FC<Props> = ({
  initialValues,
  hiddenFields,
  ticketTypeId,
  onSubmit,
}) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const { data: formConfig, isLoading } = useIntercomTicketAttributes({
    ticket_type_id: ticketTypeId,
  });
  const createTicket = useCreateIntercomTicket();

  const handleSubmit = (values: FormValues) => {
    createTicket.mutate(
      {
        ticket_attributes: values,
        ticket_type_id: ticketTypeId,
      },
      {
        onSuccess: () => {
          onSubmit?.();
          notification.success({
            message: "Plan change requested",
            description:
              "Your plan change request has been submitted. We'll update you shortly.",
          });
        },
        onError: (error) => {
          notification.error({
            message:
              error.response?.data.error.message ||
              "Enterprise plan request failed",
          });
        },
      },
    );
  };

  return (
    <Skeleton active loading={isLoading}>
      <ProForm
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleSubmit}
      >
        {formConfig?.ticketAttributes.map((field) => {
          if (hiddenFields?.includes(field.name)) {
            return (
              <Form.Item hidden name={field.name}>
                <input key={field.name} type="hidden" />
              </Form.Item>
            );
          }

          if (field.inputType === "text") {
            return (
              <ProFormText
                key={field.name}
                name={field.name}
                label={field.label}
                rules={[
                  { required: field.required, message: "Value is required" },
                ]}
              />
            );
          }

          if (field.inputType === "textarea") {
            return (
              <ProFormTextArea
                key={field.name}
                name={field.name}
                label={field.label}
                placeholder=""
                rules={[
                  { required: field.required, message: "Value is required" },
                ]}
              />
            );
          }

          if (field.inputType === "select") {
            return (
              <ProFormSelect
                key={field.name}
                name={field.name}
                label={field.label}
                rules={[
                  { required: field.required, message: "Value is required" },
                ]}
                options={field.options}
              />
            );
          }

          return null;
        })}
      </ProForm>
    </Skeleton>
  );
};

export default IntercomForm;
