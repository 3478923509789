import {
  App,
  Button,
  Form,
  Input,
  notification,
  Space,
  Typography,
} from "antd";

import { useAutoPayCardSetup, useDeletePaymentMethod } from "mutations";
import { AutoPayCard, GetWalletResponse } from "queries";
import { formatLocalisedDate } from "util/format_helpers";

interface AutoPayConfig {
  enabledCurrencies: (keyof GetWalletResponse["exchangeRates"])[];
  designatedCurrency: keyof GetWalletResponse["exchangeRates"];
  enabled: boolean;
  minBalance: number;
  payAmount: number;
  exchangeRates: GetWalletResponse["exchangeRates"];
}

interface AutoPayManageCardProps {
  card: AutoPayCard;
  autoPay: AutoPayConfig;
  onNext: (value: { dlocalKey: string; currency: string }) => void;
  onClose: () => void;
}

function AutoPayManageCard({
  card,
  autoPay,
  onNext,
  onClose,
}: AutoPayManageCardProps) {
  const { modal } = App.useApp();
  const autoPayCardSetup = useAutoPayCardSetup();
  const deletePaymentMethod = useDeletePaymentMethod();

  const onRemove = () =>
    deletePaymentMethod.mutateAsync(
      { id: card!.cardId },
      {
        onSuccess: () => {
          notification.success({ message: "Card removed successfully" });
          onClose();
        },
        onError: (res) => {
          notification.error({
            message: res.response?.data.error || "Card could not be removed",
          });
        },
      },
    );

  const replaceCard = () => {
    autoPayCardSetup.mutate(
      {
        currency: autoPay.designatedCurrency,
        replace_card: true,
        id: card.id,
      },
      {
        onSuccess: (res) => {
          onNext({
            dlocalKey: res.dlocalSfKey,
            currency: autoPay.designatedCurrency,
          });

          if (res.hasRedirect && res.redirectUrl) {
            notification.success({
              message: "You will be redirected to continue card setup",
            });
            window.location.assign(res.redirectUrl);
          }
        },
        onError: (res) => {
          notification.error({
            message:
              res.response?.data.error ||
              "Auto-Pay setup failed, please try again",
          });
        },
      },
    );
  };

  return (
    <div>
      <Typography.Paragraph>
        This card will be used for Auto-Pay. You will get a notification before
        each payment.
      </Typography.Paragraph>

      <Form className="mt-4" disabled layout="vertical">
        <Form.Item label="Card number">
          <Input value={`**** **** **** ${card.last4}`} />
        </Form.Item>
        <Form.Item label="Expiry">
          <Input value={formatLocalisedDate(card.expiration, "MM/YY")} />
        </Form.Item>
      </Form>

      <Space className="mt-4">
        <Button
          danger
          onClick={() => {
            const modalInstance = modal.confirm({
              title: "Are you sure you want to remove this card?",
              content: `Auto-Pay will be disabled if there are no cards configured for
              Auto-Pay. Also, when your wallet balance falls below the required
              amount, you won't be able to process production jobs.`,
              okText: "Remove Card",
              onOk: async () => {
                modalInstance.update({
                  okButtonProps: { danger: true, loading: true },
                  cancelButtonProps: { disabled: true },
                });
                await onRemove();
              },
              okButtonProps: {
                danger: true,
              },
              cancelText: "Cancel",
            });
          }}
          disabled={autoPayCardSetup.isPending}
        >
          Remove
        </Button>
        <Button disabled={autoPayCardSetup.isPending} onClick={replaceCard}>
          Add New
        </Button>
      </Space>
    </div>
  );
}

export default AutoPayManageCard;
