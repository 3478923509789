import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { camelize } from "util/transformers";

export interface AutoPayCard {
  id: string;
  cardId: string;
  last4: string;
  expiration: string;
}

interface GetAutoPayCardsResponse {
  paymentMethods: AutoPayCard[];
}

interface GetAutoPayCardsInput {
  currency: string;
}

const getAutoPayCards = async (
  input: GetAutoPayCardsInput,
): Promise<GetAutoPayCardsResponse> => {
  const { data } = await axios.get("/payment_methods/get_cards", {
    params: input,
  });

  return camelize<GetAutoPayCardsResponse>(data);
};

export const AUTOPAY_CARDS_QUERY_KEY = "auto-pay-cards";

export const useAutoPayCards = (
  input: GetAutoPayCardsInput,
  options?: Omit<
    UseQueryOptions<GetAutoPayCardsResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [AUTOPAY_CARDS_QUERY_KEY],
    queryFn: () => getAutoPayCards(input),
    ...options,
  });
