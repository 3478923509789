import React from "react";
import { Button, Result, Spin } from "antd";

import { usePaymentStatus } from "queries";
import { getCurrencyCountry } from "util/format_helpers";
import { formatMoney } from "util/i18n";

interface PendingPaymentProps {
  paymentRef: string;
  amount: number;
  currency: string;
  onClick: (status: "PAID" | "CANCELLED" | "REJECTED") => void;
}

const PendingPayment: React.FC<PendingPaymentProps> = ({
  onClick,
  paymentRef,
  currency,
  amount,
}) => {
  const { data } = usePaymentStatus(
    {
      country: getCurrencyCountry(currency),
      payment_ref: paymentRef,
      provider: "dlocal",
    },
    {
      refetchInterval: () => (data?.data.status === "PENDING" ? 3000 : false),
    },
  );

  const title = {
    PAID: "Payment Confirmed",
    CANCELLED: "Payment Cancelled",
    PENDING: "Processing Payment",
    REJECTED: "Payment Rejected",
    AUTHORIZED: "Payment Authorized",
    VERIFIED: "Payment Verified",
  }[data?.data.status || "PENDING"];

  const status = {
    PAID: "success" as const,
    CANCELLED: "warning" as const,
    REJECTED: "error" as const,
    PENDING: undefined,
    AUTHORIZED: undefined,
    VERIFIED: undefined,
  }[data?.data.status || "PENDING"];

  const subtitle = {
    PAID: `You added ${formatMoney(amount, currency)} to your Smile ID wallet`,
    CANCELLED: "Your payment has been cancelled.",
    PENDING: "Please wait while we confirm payment.",
    REJECTED: data?.data.statusDetail,
    AUTHORIZED: data?.data.statusDetail,
    VERIFIED: data?.data.statusDetail,
  }[data?.data.status || "PENDING"];

  return (
    <Result
      status={status}
      icon={data?.data.status === "PENDING" ? <Spin size="large" /> : undefined}
      title={title}
      subTitle={subtitle}
      extra={
        ["PAID", "CANCELLED", "REJECTED"].includes(
          data?.data.status as string,
        ) && [
          <Button
            type="primary"
            key="ok"
            onClick={() =>
              onClick?.(data!.data.status as "PAID" | "CANCELLED" | "REJECTED")
            }
          >
            {data!.data.status === "PAID" ? "Continue" : "Try Again"}
          </Button>,
        ]
      }
    />
  );
};

export default PendingPayment;
