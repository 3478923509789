import React from "react";
import { Table, Tabs, Typography } from "antd";

import AmlCheckLogo from "assets/svgs/aml-check.svg";
import BusinessVerificationLogo from "assets/svgs/business-verification.svg";
import DocumentVerificationLogo from "assets/svgs/document-verification.svg";
import DocVAuthenticity from "assets/svgs/docv-authenticity.svg";
import DocVFaceMatch from "assets/svgs/docv-face-match.svg";
import DocVFrontAndBack from "assets/svgs/docv-front-and-back.svg";
import Loader from "components/ui/Loader";
import { usePartnerInfo } from "queries";
import { formatMoney } from "util/i18n";
import { getAdminPartnerId } from "util/selectors";

const PricingInfo: React.FC = () => {
  const adminPartnerId = getAdminPartnerId();
  const { data: partnerInfo, isLoading } = usePartnerInfo({ adminPartnerId });

  if (isLoading) {
    return <Loader size="large" />;
  }

  const productPricing = partnerInfo!.productPricing;

  return (
    <Tabs>
      <Tabs.TabPane key="aml" tab="AML Check">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Typography.Paragraph>
              Available in all markets.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong className="text-lg">
                {formatMoney(productPricing.aml, "USD")}
              </strong>{" "}
              per query
            </Typography.Paragraph>
          </div>

          <div className="grid grid-cols-[auto_1fr] gap-4">
            <img className="w-24" alt="" src={AmlCheckLogo} />
            <div>
              <Typography.Title level={4}>AML Check</Typography.Title>
              <Typography.Paragraph>
                Screen new and existing users against over 1100 global and
                African sanctions list, PEP and adverse media watchlists.
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane key="businessVerification" tab="Business Verification">
        <div className="grid grid-cols-2 gap-4">
          <div>
            {productPricing.businessVerification?.map(
              ({ country, services }) => (
                <Table
                  className="w-72 mb-12 break-inside-avoid"
                  size="small"
                  key={country}
                  columns={[
                    { title: country, dataIndex: "service" },
                    {
                      title: "Fee",
                      dataIndex: "cost",
                      render: (value) => formatMoney(value, "USD"),
                    },
                  ]}
                  dataSource={services}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              ),
            )}
          </div>
          <div className="grid grid-cols-[auto_1fr] gap-4">
            <img alt="" src={BusinessVerificationLogo} />
            <div>
              <Typography.Title level={4}>
                Business Verification
              </Typography.Title>
              <Typography.Paragraph>
                Verify business and beneficial owner information with real-time
                checks of government registration databases.
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane key="docV" tab="Document Verification">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Typography.Paragraph>
              Available in all markets.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong className="text-lg">
                {formatMoney(productPricing.docV, "USD")}
              </strong>{" "}
              per query
            </Typography.Paragraph>
          </div>
          <div>
            <div className="grid grid-cols-[auto_1fr] gap-4">
              <img className="w-24" alt="" src={DocumentVerificationLogo} />
              <div>
                <Typography.Title level={4} className="h4">
                  Document Verification
                </Typography.Title>
                <Typography.Paragraph>
                  Verify your user's identity using a government-issued ID
                  document and a selfie.
                </Typography.Paragraph>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-[auto_1fr] gap-4">
                <img className="w-8" alt="" src={DocVFrontAndBack} />
                <Typography.Paragraph>
                  Verify front and back of IDs.
                </Typography.Paragraph>
              </div>
              <div className="grid grid-cols-[auto_1fr] gap-4">
                <img className="w-8" alt="" src={DocVAuthenticity} />
                <Typography.Paragraph>
                  Authenticity checks of documents.
                </Typography.Paragraph>
              </div>
              <div className="grid grid-cols-[auto_1fr] gap-4">
                <img className="w-8" alt="" src={DocVFaceMatch} />
                <Typography.Paragraph>
                  Validate ID photo information with selfies.
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Government KYC Check">
        <Tabs>
          <Tabs.TabPane key="basic" tab="Basic KYC">
            <Typography.Paragraph>
              Match personal information against official government records.
            </Typography.Paragraph>
            <div className="columns-3">
              {productPricing.basicKyc?.map(({ country, services }) => (
                <Table
                  className="w-72 mb-12 break-inside-avoid"
                  size="small"
                  key={country}
                  columns={[
                    { title: country, dataIndex: "service" },
                    {
                      title: "Fee",
                      dataIndex: "cost",
                      render: (value) => formatMoney(value, "USD"),
                    },
                  ]}
                  dataSource={services}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="enhanced" tab="Enhanced KYC">
            <Typography.Paragraph>
              Retrieve personal information from official government records.
            </Typography.Paragraph>
            <div className="columns-3">
              {productPricing.enhancedKyc?.map(({ country, services }) => (
                <Table
                  className="w-72 mb-12 break-inside-avoid"
                  size="small"
                  key={country}
                  columns={[
                    { title: country, dataIndex: "service" },
                    {
                      title: "Fee",
                      dataIndex: "cost",
                      render: (value) => formatMoney(value, "USD"),
                    },
                  ]}
                  dataSource={services}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="biometric" tab="Biometric KYC">
            <Typography.Paragraph>
              Retrieve personal information from government records and match a
              selfie to official ID photo.
            </Typography.Paragraph>
            <div className="columns-3">
              {productPricing.biometricKyc?.map(({ country, services }) => (
                <Table
                  className="w-72 mb-12 break-inside-avoid"
                  size="small"
                  key={country}
                  columns={[
                    { title: country, dataIndex: "service" },
                    {
                      title: "Fee",
                      dataIndex: "cost",
                      render: (value) => formatMoney(value, "USD"),
                    },
                  ]}
                  dataSource={services}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="enhancedDocV" tab="Enhanced Document Verification">
            <Typography.Paragraph>
              Combine document verification with government records and match
              selfies in a single check.
            </Typography.Paragraph>
            <div className="columns-3">
              {productPricing.enhancedDocVKyc?.map(({ country, services }) => (
                <Table
                  className="w-72 mb-12 break-inside-avoid"
                  size="small"
                  key={country}
                  columns={[
                    { title: country, dataIndex: "service" },
                    {
                      title: "Fee",
                      dataIndex: "cost",
                      render: (value) => formatMoney(value, "USD"),
                    },
                  ]}
                  dataSource={services}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              ))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Tabs.TabPane>
      <Tabs.TabPane key="others" tab="Others">
        <Table
          className="w-72 break-inside-avoid"
          size="small"
          columns={[
            { title: "Service", dataIndex: "service" },
            {
              title: "Fee",
              dataIndex: "cost",
              render: (value) => formatMoney(value, "USD"),
            },
          ]}
          dataSource={productPricing.others}
          pagination={{ hideOnSinglePage: true, pageSize: 20 }}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default PricingInfo;
