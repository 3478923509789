import React from "react";
import { useSetState } from "ahooks";
import { Button, Modal, Result } from "antd";
import { useHistory } from "react-router-dom";

import PendingPayment from "components/payment-plans/PendingPayment";
import CountryPicker from "components/reusable/country_picker";
import { useQueryParams } from "hooks/useQueryParams";
import CardIcon from "resources/img/icons/card.svg";
import { createInvoice, fetchWallet } from "util/api_util";
import {
  convertCurrency,
  formatCurrency,
  getCurrencyCountry,
} from "util/format_helpers";
import { CurrencyInput } from "./currency_input";
import DLocalPaymentForm from "./DLocalPaymentForm";

/**
 * Formats a payload for a payment provider.
 * @param {object} state - The state of the component
 * @param {number} state.amount - The amount to be paid.
 * @param {string} state.currency - The currency of the amount.
 * @returns {{
 * amount: number,
 * currency: string,
 * partner_id: string|undefined
 * }} - body of the request.
 */
const generatedInvoiceRequest = ({ amount, currency }) => {
  const body = { amount, currency };
  if (localStorage.adminPartnerId) {
    body.partner_id = localStorage.adminPartnerId;
  }
  return body;
};

/**
 * Validates that amount is greater than a minimum funding amount.
 * @param {object} state - The state of the component.
 * @param {number} state.amount - The amount to validate.
 * @param {string} state.currency - The currency of the amount.
 * @param {object} state.exchangeRates - The exchange rates to USD indexed by ISO country code.
 * @returns {number} - minimum funding amount.
 * }
 */
const validateAmount = ({ amount, currency, exchangeRates }) => {
  // 20 US dollars in local currency.
  const minAmount = 20 * exchangeRates[currency];
  if (parseFloat(amount) < minAmount) {
    throw new Error(
      `${formatCurrency(minAmount, currency)} is the minimum funding amount.`,
    );
  }
  return minAmount;
};

/**
 * Default payment amounts by currency. Minimum value
 * approximately corresponds to 500 USD.
 */
const defaultPaymentAmounts = {
  USD: [500, 1000, 2000, "Other"],
  NGN: [200000, 400000, 800000, "Other"],
  KES: [50000, 100000, 200000, "Other"],
  ZAR: [5000, 10000, 20000, "Other"],
};

function FundWallet() {
  const queryParams = useQueryParams();
  const history = useHistory();
  const defaultCurrency = (
    queryParams.currency ||
    sessionStorage.getItem("currency") ||
    "USD"
  ).toUpperCase();

  const [state, setState] = useSetState({
    loading: false,
    disabled: false,
    currency: defaultCurrency,
    amount: defaultPaymentAmounts[defaultCurrency]?.[0] ?? 500,
    quickAmount: defaultPaymentAmounts[defaultCurrency]?.[0] ?? 500,
    modalOpen: false,
    flwvKey: undefined,
    dlocalKey: undefined,
    paymentStatus: queryParams.status?.toLowerCase(),
    amountPaid: queryParams.amount,
    paymentRef: queryParams.paymentRef,
  });

  React.useEffect(() => {
    sessionStorage.setItem("url", `/partner/billing/payment`);

    // when we redirect back to this component from stripe when want to clear
    // the query params, so when the user refresh the page manually they
    // don't get the payment success message
    if (Object.keys(queryParams).length > 0) {
      history.replace("/partner/billing/payment");
    } else {
      getData();
    }
  }, []);

  const getData = () => {
    const params = {};
    if (localStorage.adminPartnerId) {
      params.partner_id = localStorage.adminPartnerId;
    }

    setState({ loading: true, disabled: true });
    fetchWallet(params).then(
      ({ wallet_balance: walletBalance, exchange_rates: exchangeRates }) => {
        setState({
          walletBalance,
          exchangeRates,
          loading: false,
          disabled: false,
          minAmount: 20 * exchangeRates[state.currency],
        });
      },
    );
  };

  const redirectBack = () => {
    history.push(window.location.pathname.replace("/payment", ""));
  };

  const onCreateInvoice = async () => {
    try {
      setState({
        disabled: true,
        loading: true,
        error: undefined,
        minAmount: validateAmount(state),
      });
      const body = generatedInvoiceRequest(state);
      const resp = await createInvoice(body);
      if (body.currency.toLowerCase() === "usd" && resp.checkout_url) {
        window.open(resp.checkout_url, "_self");
      } else {
        setState({
          error: resp.error,
          invoice: resp.invoice,
          disabled: false,
          loading: false,
          dlocalKey: resp.dlocal_sf_key,
          paymentMethods: resp.payment_methods,
          flwvKey: resp.flwv_key,
        });
      }
    } catch (e) {
      setState({
        disabled: false,
        loading: false,
        error: e.message,
      });
    }
  };

  const setCurrency = (currency) => {
    setState({
      currency,
      pause: true,
    });
    // It is very unfortunate but this set timeout is required, because otherwise
    // the modal loads with whatever the original currency/country is during the
    // initial page load.
    setTimeout(() => {
      setState({
        pause: false,
      });
    }, 100);
  };

  const paymentCallback = () => {
    setState({
      amount: 500,
      amountPaid: undefined,
      disabled: true,
      dlocalKey: null,
      invoice: null,
      loading: true,
      paymentStatus: undefined,
      quickAmount: 500,
    });
    getData();
  };

  /**
   * Updates state with the quick amount selected by the user.
   * @param {number|string} option - amount of money in native currency or string Other
   * @returns {void}
   */
  const setQuickAmount = (option) => {
    if (state.disabled) {
      return;
    }
    setState({
      amount: option === "Other" ? undefined : option,
      quickAmount: option,
    });
  };

  const {
    amount,
    disabled,
    currency,
    dlocalKey,
    invoice,
    loading,
    paymentMethods,
    paymentStatus,
    quickAmount,
    walletBalance,
    paymentRef,
    amountPaid,
  } = state;

  const paymentAmounts = defaultPaymentAmounts?.[currency] ?? [];
  const options = paymentAmounts.map((option) => {
    const formattedAmount =
      option === "Other" ? option : formatCurrency(option, currency);
    return (
      <button
        type="button"
        key={option}
        onClick={() => setQuickAmount(option)}
        className={[
          "fund-wallet__options",
          quickAmount === option ? " active" : "",
          disabled ? " disabled" : "",
        ]
          .filter((x) => x)
          .map((x) => x.trim())
          .join(" ")}
      >
        <p className="fund-wallet__options-button-text">{formattedAmount}</p>
      </button>
    );
  });

  return (
    <div className="legacy">
      <div className="breadcrumbs">
        <a
          data-value="billing"
          className="breadcrumb-link breadcrumb-link--inactive"
          onClick={redirectBack}
        >
          Billing
        </a>
        <span> &gt; </span>
        <span>Fund Wallet</span>
      </div>
      <div className="web-app__container">
        <div className="web-app__container-header">
          <div className="web-app__container-header">
            <img
              className="web-app__header-icon icon--large"
              src={CardIcon}
              alt="lock"
            />
            <h1 className="metrics__header">Fund Wallet</h1>
          </div>
        </div>
        <div className="web-app__container-body">
          <div
            className={`web-app__wizard-loading ${loading ? "loader" : ""}`}
          />
          <div className="web-app__wizard-form web-app__wizard-form--top-level">
            <div className="web-app__wizard-error error">{state.error}</div>
            <div>
              <a
                href="https://docs.usesmileid.com/getting-started/fund-your-wallet"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supported Payment Methods
              </a>
            </div>
            <div className="web-app__wizard-form-item">
              <CountryPicker
                callback={setCurrency}
                currency={currency}
                disabled={disabled}
              />
              <h2>
                Current Balance:{" "}
                {formatCurrency(
                  parseFloat(convertCurrency({ state }, walletBalance ?? 0)),
                  currency,
                )}
              </h2>
            </div>

            <div className="web-app__wizard-form-item">
              {options.length > 0 && <label className="label">Amount</label>}
              {options}

              {quickAmount === "Other" ? (
                <CurrencyInput
                  autoFocus
                  id="other"
                  className="input"
                  name="amount"
                  type="number"
                  onChange={(amount) => {
                    setState({ amount });
                  }}
                  value={state.amount}
                  minimumValue={state.minAmount}
                  disabled={disabled}
                  currency={currency}
                />
              ) : null}
            </div>
            <div className="web-app__wizard-buttons">
              <button
                className="btn btn-primary web-app__wizard-button--submit"
                onClick={() => onCreateInvoice()}
                disabled={disabled || loading}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        title="Fund Wallet"
        onCancel={() => setState({ dlocalKey: null, invoice: null })}
        open={invoice && dlocalKey}
      >
        <DLocalPaymentForm
          onSubmit={paymentCallback}
          amount={parseFloat(amount)}
          invoiceId={invoice?.invoice_id}
          currency={currency}
          apiKey={dlocalKey}
          paymentMethods={paymentMethods}
        />
      </Modal>

      {!loading && paymentStatus && (
        <PendingModal
          open
          onClick={() => {
            setState({
              paymentStatus: undefined,
              amountPaid: undefined,
              loading: true,
              disabled: true,
            });
            getData();
          }}
          paymentStatus={paymentStatus}
          paymentRef={paymentRef}
          currency={currency}
          amount={amountPaid}
        />
      )}
    </div>
  );
}

function PendingModal({
  paymentStatus,
  onClick,
  show,
  paymentRef,
  currency,
  amount,
}) {
  if (!show) {
    return null;
  }
  let title = "Payment Rejected";
  let message = "";
  const isPending = paymentStatus === "pending";
  const isApproved = paymentStatus === "approved";
  const isExpired = paymentStatus === "expired";

  if (isApproved) {
    title = "Funds Added Successfully!";
    message =
      "It may take a few moments for your balance to reflect your most recent payment.";
  } else if (isExpired) {
    title = "Session Expired";
    message = "Please retry payment";
  }

  if (isPending) {
    return (
      <PendingPayment
        currency={currency}
        amount={amount}
        paymentRef={paymentRef}
        onClick={onClick}
      />
    );
  }

  return (
    <Result
      type={
        { approved: "success", pending: "info", expired: "error" }[
          paymentStatus
        ]
      }
      title={title}
      subTitle={message}
      extra={[
        <Button key="ok" type="primary" onClick={onClick}>
          Continue
        </Button>,
      ]}
    />
  );
}

export default FundWallet;
