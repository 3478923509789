import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { camelize } from "util/transformers";

export interface CreatePaymentMethodResponse {
  success: boolean;
  code?: number;
  message?: string;
  paymentRef?: string;
  cardId: {
    id: number;
    cardId: string;
    cardType: "mastercard" | "visa" | "verve";
    expiration: string;
    default: boolean;
    paymentProvider: "dlocal" | "stripe";
    companyId: number;
    createdAt: string;
    updatedAt: string;
    last4: string;
    isoCountryCode: string;
  };
}

export interface CreatePaymentMethodInput {
  currency: string;
  country: string;
  card_type: string;
  name?: string;
  card_id: string;
  payment_method: "CARD";
  document_id: string;
  partner_id?: string;
  dlocal_token?: string;
}

const createPaymentMethod = async (
  input: CreatePaymentMethodInput,
): Promise<CreatePaymentMethodResponse> => {
  const { data } = await axios.post<CreatePaymentMethodResponse>(
    "/payment_methods/save_card",
    input,
  );
  return camelize<CreatePaymentMethodResponse>(data);
};

export const useCreatePaymentMethod = (
  options?: UseMutationOptions<
    CreatePaymentMethodResponse,
    AxiosError<{ error: string }>,
    CreatePaymentMethodInput
  >,
) =>
  useMutation({
    mutationFn: createPaymentMethod,
    ...options,
  });
