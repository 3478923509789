import { Spin, SpinProps } from "antd";

interface Props extends SpinProps {}

const Loader: React.FC<Props> = (props) => (
  <div className="w-full h-full min-h-72 grid place-items-center">
    <Spin {...props} />
  </div>
);

export default Loader;
