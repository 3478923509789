import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { camelize } from "util/transformers";

interface IntercomTextFieldAttributes {
  inputType: "text";
  label: string;
  name: string;
  required: boolean;
}

interface IntercomSelectFieldAttributes {
  inputType: "select";
  label: string;
  name: string;
  required: boolean;
  options: {
    label: string;
    value: string;
  }[];
}

interface IntercomTextAreaFieldAttributes {
  inputType: "textarea";
  label: string;
  name: string;
  required: boolean;
}

interface GetIntercomTicketAttributesResponse {
  ticketAttributes: (
    | IntercomTextFieldAttributes
    | IntercomSelectFieldAttributes
    | IntercomTextAreaFieldAttributes
  )[];
}

interface GetIntercomTicketAttributesInput {
  ticket_type_id: string;
}

const getIntercomTicketAttributes = async ({
  ticket_type_id,
}: GetIntercomTicketAttributesInput) => {
  const { data } = await axios.get<GetIntercomTicketAttributesResponse>(
    "/intercom_tickets/ticket_attributes",
    { params: { ticket_type_id } },
  );
  return camelize<GetIntercomTicketAttributesResponse>(data);
};

export const INTERCOM_TICKET_ATTRIBUTES_QUERY_KEY =
  "intercom-ticket-attributes";

export const useIntercomTicketAttributes = (
  input: GetIntercomTicketAttributesInput,
  options?: Omit<
    UseQueryOptions<
      GetIntercomTicketAttributesResponse,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [INTERCOM_TICKET_ATTRIBUTES_QUERY_KEY, input],
    queryFn: () => getIntercomTicketAttributes(input),
    ...options,
  });
